import { useState } from "react";
import "./ImageWithSkeleton.css";
import { Box, Skeleton } from "@mui/material";
import Zoom from 'react-medium-image-zoom';

type ImageWithSkeletonProps = {
    src: string;
    alt: string;
};

export function ImageWithSkeleton(props: ImageWithSkeletonProps): JSX.Element {
    const [loaded, setLoaded] = useState(false);
    return (
        <div className="ImageWithSkeleton">
            {!loaded ? (
                <Box >
                <Skeleton variant="rounded" animation="wave">
                    <img
                        src={props.src}
                        alt={props.alt}
                        style={{ visibility: 'hidden' }}
                        onLoad={() => setLoaded(true)}
                        className="describe-image"
                    />
                </Skeleton>
                </Box>
            ) : (
                <Zoom>
                    <img
                        src={props.src}
                        alt={props.alt}
                        className="describe-image"
                    />
                </Zoom>
            )}
        </div>
    );
}
